import { EnvironmentBase } from "./environment-base";

// tslint:disable:no-inferrable-types
class Environment extends EnvironmentBase {
    public production: boolean = true;
    public stage: boolean = false;
    public dev: boolean = false;
    public cdnHost: string = 'https://static.contextall.com/';
    public apiHost: string = 'https://api.contextall.com/';
    public MICROSOFT_APP_ID: string = "cc8dd017-81df-4b71-bf35-bdf9e9b55177";
}
export const environment = new Environment();
